.ventas-mi-lista-clientes-table {
  table-layout: fixed !important;
  width: 100% !important;

  tr {
    height: 45px !important; // Altura fija para dos líneas
  }

  th, td {
    white-space: normal !important;
    word-wrap: break-word !important;
    vertical-align: middle !important; // Centrar verticalmente
    line-height: 1.5 !important; // Ajustar line-height para centrar el texto
    font-size: 0.85em !important; // Reducir el tamaño de la tipografía en la tabla
  }

  .col-cliente { width: 18% !important; }
  .col-contacto { width: 18% !important; }
  .col-tipo { width: 5% !important; }
  .col-importes { width: 10% !important; }
  .col-7-5 { width: 10% !important; min-width: 120px !important; }
  .col-12 { width: 12% !important; }
  .col-8 { width: 8% !important; }
  .col-edit { width: 5% !important; }

  th, td {
    max-width: 0 !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    padding: 0.2rem !important; // Ajustar padding
  }

  &.table {
    th, td {
      min-width: 0 !important;
    }
  }

  td[colspan="2"],
  th[colspan="2"] {
    width: 20% !important;
    min-width: 240px !important;
  }

  // Estilos generales para los campos de edición
  .small-input {
    width: 200px !important; // Ancho estándar para todos los campos de edición
    font-size: 0.9em !important; // Tamaño de tipografía más pequeño para los campos
    text-align: start !important; // Alineación a la izquierda como prefieres
    padding: 2px !important; // Reducir padding para un diseño más limpio
    border: 1px solid #ccc !important; // Borde ligero
    border-radius: 4px !important; // Esquinas redondeadas
  }

  // Estilos específicos para "Tipo" en modo edición
  .edit-tipo .small-input {
    width: 40px !important; // Ancho más pequeño para "Tipo"
  }

  // Estilos específicos para "Último Contacto" en modo edición
  .edit-ultimo-contacto .small-input {
    width: 100px !important; // Ancho ajustado para fechas y acciones
    text-align: center !important; // Alineación a la izquierda como prefieres
  }

  // Estilos específicos para "Próximo Contacto" en modo edición
  .edit-proximo-contacto .small-input {
    width: 100px !important; // Ancho ajustado para fechas y acciones
    text-align: center !important; // Alineación a la izquierda como prefieres
  }

  // Estilos para los estados de contacto
  .contacto-status {
    display: inline-block;
    padding: 2px 6px;
    border-radius: 4px;
  }

  .contacto-status.expired {
    background-color: #ffcccc !important; // Rojo suave
    color: #800000 !important;
  }

  .contacto-status.todayTomorrow {
    background-color: #ffe6cc !important; // Naranja suave para "Hoy" y "Mañana"
    color: #856404 !important;
  }

  .contacto-status.thisWeek {
    background-color: #fff3cd !important; // Amarillo suave para "Esta semana"
    color: #856404 !important;
  }

  .contacto-status.nextWeek {
    background-color: #cce5ff !important; // Azul suave para "Semana que viene"
    color: #004085 !important;
  }

  .contacto-status.later {
    background-color: #d4edda !important; // Verde suave para "Dos o más semanas"
    color: #155724 !important;
  }

  .contacto-status.default {
    background-color: transparent !important;
    color: inherit !important;
  }

  @media (max-width: 768px) {
    tr {
      height: 40px !important; // Ajustar altura para pantallas pequeñas
    }
    .col-7-5 { width: 12% !important; min-width: 100px !important; }
    td[colspan="2"], th[colspan="2"] { width: 24% !important; min-width: 200px !important; }
    th, td {
      font-size: 0.75em !important; // Reducir tipografía en modo responsive
      padding: 0.1rem !important; // Reducir padding para pantallas pequeñas
      line-height: 1.5 !important;
    }
    .small-input {
      width: 80px !important; // Reducir ancho en modo responsive
      font-size: 0.7em !important; // Reducir tipografía en modo responsive
    }
    .edit-tipo .small-input {
      width: 60px !important; // Reducir ancho en modo responsive
    }
    .edit-ultimo-contacto .small-input,
    .edit-proximo-contacto .small-input {
      width: 90px !important; // Ajustar ancho en modo responsive
    }
    .contacto-status { padding: 1px 4px; }
  }
}