.table thead th.bg-success {
  background-color: #28a745 !important; // Verde de Bootstrap para success
}
.table thead th.bg-danger {
  background-color: #dc3545 !important; // Rojo de Bootstrap para danger
}
.table thead th.bg-primary {
  background-color: #007bff !important; // Azul de Bootstrap para primary
}
.table thead th.bg-warning {
  background-color: #ffc107 !important; // Amarillo de Bootstrap para warning
}
.table thead th.bg-info {
  background-color: #17a2b8 !important; // Celeste de Bootstrap para info
}
