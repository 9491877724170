// Table styling (existing styles, unchanged)
.ventas-proyeccion {
    table-layout: fixed !important; // Fuerza un diseño de tabla fijo para respetar los anchos
    width: 80% !important; // Cambia el ancho al 80% del contenedor padre
    margin-top: 50px !important; // Añade un margin-top de 50px
    margin-left: auto !important; // Centra la tabla horizontalmente
    margin-right: auto !important; // Centra la tabla horizontalmente
  
    // Container for the logo
    .logo-container {
      text-align: center; // Centers the logo horizontally
      margin-bottom: 1rem; // Adds spacing below the logo (equivalent to Bootstrap's mb-4)
      display: flex; // Use flexbox to ensure proper centering
      justify-content: center; // Horizontally center the logo
    }
    
    // Styling for the logo image
    .logo {
      max-width: 100px; // Maintains the smaller size as requested
      height: auto; // Maintains aspect ratio
    }
  }
