// Table styling (existing styles, unchanged)
.inventario-simple-table {
    table-layout: fixed !important; // Fuerza un diseño de tabla fijo para respetar los anchos
    width: 80% !important; // Cambia el ancho al 80% del contenedor padre
    margin-top: 50px !important; // Añade un margin-top de 50px
    margin-left: auto !important; // Centra la tabla horizontalmente
    margin-right: auto !important; // Centra la tabla horizontalmente
  
    .inventario-simple-container {
      width: 100%; // Ocupa el 100% del ancho del contenedor padre
      padding: 0 10px; // Opcional: añade padding si necesitas espacio lateral
    }
    
    // Container for the logo
    .logo-container {
      text-align: center; // Centers the logo horizontally
      margin-bottom: 1rem; // Adds spacing below the logo (equivalent to Bootstrap's mb-4)
      display: flex; // Use flexbox to ensure proper centering
      justify-content: center; // Horizontally center the logo
    }
    
    // Styling for the logo image
    .logo {
      max-width: 100px; // Maintains the smaller size as requested
      height: auto; // Maintains aspect ratio
    }
    
    th, td {
      white-space: nowrap !important; // Evita que el texto se rompa si es largo (kept for general styling, but overridden below)
    }
  
    .col-3 { width: 3% !important; }
    .col-15 { width: 15% !important; }
    .col-8 { width: 8% !important; }
    .col-18 { width: 18% !important; }
    .col-5 { width: 5% !important; }
    .col-10 { width: 10% !important; } // New/updated width for STATUS and PROGRAMMATIC
    .col-20 { width: 20% !important; } // Updated width for EDIFICIO and PANTALLAS
  
    th, td {
      max-width: 0 !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important; // Default for most columns, but overridden below
    }
  
    // Override for EDIFICIO and PROGRAMMATIC to remove ellipsis and allow full text
    th.col-20, td.col-20,
    th.col-10, td.col-10 {
      overflow: visible !important; // Allow text to show fully
      text-overflow: clip !important; // No ellipsis, just cut off if needed (or remove this line)
      white-space: nowrap !important; // Keep text on a single line
    }
  
    // Sobrescribe estilos específicos de Bootstrap para esta tabla
    &.table {
      th, td {
        min-width: 0 !important; // Evita que las celdas se expandan más allá de su ancho definido
        padding: 0.3rem !important; // Ajusta el padding para table-sm
      }
  
      th {
        background-color: #212529 !important; // Fondo oscuro similar al table-dark
        color: white !important;
      }
  
      tr:hover {
        background-color: #f8f9fa !important; // Efecto hover similar al table-hover
      }
  
      tr:nth-child(even) {
        background-color: #f8f9fa !important; // Stripes similares al table-striped
      }
  
      &.table-sm {
        th, td {
          padding: 0.3rem !important; // Asegura el padding reducido de table-sm
        }
      }
    }
  
    // Añade más especificidad para las clases col-*
    th.col-3, td.col-3 { width: 3% !important; }
    th.col-15, td.col-15 { width: 15% !important; }
    th.col-8, td.col-8 { width: 8% !important; }
    th.col-18, td.col-18 { width: 18% !important; }
    th.col-5, td.col-5 { width: 5% !important; }
    th.col-10, td.col-10 { width: 10% !important; } // Ensure this is applied to STATUS and PROGRAMMATIC
    th.col-20, td.col-20 { width: 20% !important; } // Ensure this is applied to EDIFICIO and PANTALLAS
  
    // Estilos adicionales para las clases de estado
    .estadoOperativo { background-color: green !important; color: white !important; }
    .estadoProximo { background-color: darkorange !important; color: white !important; }
    .estadoStandby { background-color: red !important; color: white !important; }
    .estadoInstalacion { background-color: orange !important; color: white !important; }
  
    // Estilo para las columnas con texto centrado (si es necesario)
    .texto {
      text-align: center !important;
    }
  
    // Estilo para las columnas con texto a la izquierda
    .text-start {
      text-align: left !important;
    }
  }