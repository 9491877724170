.lista-edificios {
    width: 100%;
    th, td {
      vertical-align: middle !important; // Ensures content is centered vertically
    }
  
    // Column-specific widths
    .col-check { width: 5%; }
    .col-edificio { width: 20%; }
    .col-ubicacion { width: 15%; }
    .col-ascensores { width: 10%; }
    .col-circulacion { width: 10%; }
    .col-espera { width: 10%; }
    .col-personas { width: 15%; }
  
    // Ensure text alignment stays consistent
    .text-center { text-align: center; }
    .text-start { text-align: left; }
  }