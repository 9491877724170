.Container {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  width: 100%;

  .header {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    width: 100%;
    background: white;

    .logo {
      display: block;
      height: 70px;
      margin: 0 auto;
    }

    .titulo {
      font-size: 32px;
      margin: 0;
      padding: 0;
    }
  }
}