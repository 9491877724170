// Table styling (updated to emphasize margin-top and width)
.listado-b2b-vetos {
  table-layout: fixed !important; // Mantén el diseño fijo para respetar los anchos
  width: 80% !important; // Asegúrate de que la tabla use el 80% del contenedor padre
  margin-top: 50px !important; // Asegúrate de que se aplica el margin-top (aumentada especificidad)
  margin-left: auto !important; // Centra la tabla horizontalmente
  margin-right: auto !important; // Centra la tabla horizontalmente
}

// Container for the logo
.logo-container {
  text-align: center; // Centers the logo horizontally
  margin-bottom: 1rem; // Adds spacing below the logo (equivalent to Bootstrap's mb-4)
  display: flex; // Use flexbox to ensure proper centering
  justify-content: center; // Horizontally center the logo
}
// Styling for the logo image (smaller as requested)
.logo {
  max-width: 100px; // Maintains the smaller size as requested
  height: auto; // Maintains aspect ratio
}
th, td {
  white-space: nowrap !important; // Mantén para evitar saltos de línea en "EDIFICIO" y "CANTIDAD"
}

.col-2 { width: 20% !important; }
.col-1 { width: 10% !important; }
.col-9 { width: 70% !important; }

th, td {
    max-width: 0 !important;
    // Elimina overflow: hidden y text-overflow: ellipsis para toda la tabla, pero aplica solo a "EDIFICIO" y "CANTIDAD"
}

// Aplica overflow y ellipsis solo a "EDIFICIO" y "CANTIDAD", no a "EMPRESAS"
th.col-2, td.col-2,
th.col-1, td.col-1 {
  max-width: 0 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important; // Trunca texto largo en "EDIFICIO" y "CANTIDAD"
}

// Para "EMPRESAS" (col-9), permite que el texto se expanda o ajuste sin truncamiento
th.col-9, td.col-9 {
  white-space: normal !important; // Permite saltos de línea para mostrar todo el texto
  overflow: visible !important; // Muestra todo el texto, sin ocultar ni truncar
  max-width: none !important; // Elimina cualquier límite de ancho
  word-wrap: break-word !important; // Permite que las palabras largas se dividan en varias líneas
}

.highlight {
  background-color: yellow; // Color de fondo amarillo para resaltar el texto
  font-weight: bold; // Opción para hacer el texto resaltado en negrita
}

@media (max-width: 768px) {
  .listado-b2b-nuevo-table {
    table-layout: auto !important; // Permite un diseño más flexible en móviles
    width: 100% !important; // Ancho completo en móviles, override del 80%

    th, td {
      white-space: normal !important; // Permite saltos de línea en todas las columnas
      max-width: none !important;
      overflow: visible !important;
    }

    .col-2, .col-1, .col-9 {
      width: auto !important; // Anchos automáticos en móviles
    }
  }
}
