// styles.scss
@import url('https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz:300,400,700');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Home {
  align-items: center;
  background-image: url("../../img/background.png");
  background-size: cover;
  background-position: center;
  color: #fff;
  display: flex;
  font-family: "poppins-semibold", sans-serif;
  height: 100vh;
  justify-content: center;
  backdrop-filter: blur(1px);

  .container-auxiliar {
    width: 95%;
    z-index: 1;
  }

  .logoHome {
    display: inline;
    backdrop-filter: blur(1px);
    width: 50%;
    margin-bottom: 3rem;
  }

  .bajada {
    font-size: 5vw;
    font-weight: bolder;
    line-height: 1.22;
    backdrop-filter: blur(1px);
    width: fit-content;
  }

  .texto-titulo {
    padding: 0;
    font-size: 2.5rem;
    color: white;
    text-align: left;
    width: 80%;
    font-weight: bold;
  }

  .texto {
    width: 85%;
    font-size: 1.5rem;
    color: white;
    text-align: left;
  }

  .bajada {
    font-size: 1rem;
    color: white;
    text-align: left;
  }

  .correo {
    font-size: 1.5rem;
    font-weight: normal;
    text-align: left;
    width: fit-content;
  }
}

.red-gradient {
  background: linear-gradient(to right, rgba(225, 0, 26, 1) 0%, rgba(79, 0, 8, 1) 100%);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-content {
  color: #fff;
  font-family: 'Yanone Kaffeesatz', sans-serif;
  letter-spacing: 0.03em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  min-height: 70vh;
  width: 100%;
}

.logo {
  font-size: 150px; // Manteniendo el tamaño que indicaste
  display: block;
  font-weight: 200;
  margin: 0;
  text-align: center;

  .bold {
    font-weight: 600;
  }
}

.subcontent {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .phrase {
    font-weight: 200;
    font-size: 40px; // Manteniendo el tamaño que indicaste
    border-bottom: 1px solid white;
    text-transform: uppercase;
    margin: 0;
    height: 1.3em;
    display: inline-block;
    text-align: center;
  }

  .countries-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0; // Sin espacio entre nombres y banderas
    margin-top: 30px; // Manteniendo el espacio arriba del bloque de países/banderas
  }

  .countries-names, .countries-flags {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
  }

  .countries-names {
    .country-1, .country-2, .country-3 {
      font-size: 30px; // Tamaño que indicaste para los nombres
      margin: 0 30px; // Espaciado ligero para mantener legible
      text-align: center;
      flex: 0 0 auto;
      min-width: 80px; // Ancho mínimo para mantener alineación
      padding: 0 !important; // Forzando eliminación de padding
    }
    .divider-1, .divider-2 {
      font-size: 25px; // Tamaño ajustado para los divisores
      margin: 0;
      width: 10px; // Divisor pequeño pero visible
      text-align: center;
      padding: 0 !important; // Forzando eliminación de padding
    }
  }

  .countries-flags {
    .flag {
      width: 40px !important; // Forzando tamaño de 50px con máxima especificidad
      max-width: 40px !important;
      height: auto !important;
      margin: 0 30px; // Espaciado ligero para mantener legible
      display: block;
      object-fit: contain !important; // Asegurando que la imagen se ajuste
      flex: 0 0 auto;
      min-width: 80px; // Mismo ancho mínimo que los nombres
      padding: 0 !important; // Forzando eliminación de padding
    }
    .divider-1, .divider-2 {
      width: 10px; // Mismo ancho que los divisores de nombres
      margin: 0;
      padding: 0 !important; // Forzando eliminación de padding
    }
  }
}

.country-1 { animation-delay: 1s; }
.country-2 { animation-delay: 1.4s; }
.country-3 { animation-delay: 1.8s; }
.divider-1 { animation-delay: 1.2s; }
.divider-2 { animation-delay: 1.6s; }