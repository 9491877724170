/* Estilos para el contenedor principal */
.cotizador-programmatic-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh; /* Asegurar que ocupe al menos toda la altura de la viewport */
  padding: 25px;
}

/* Estilos para la tabla fija (CotizadorProgrammaticCalculadora) */
.calculator-fixed {
  position: fixed; /* Mantener la posición fija para que no se mueva al hacer scroll */
  top: 0px; /* Ajustar según el padding del contenedor principal */
  left: 50%; /* Centrar horizontalmente */
  transform: translateX(-50%); /* Ajuste para centrar correctamente */
  width: 100%;
  max-width: 1600px; /* Igualar el ancho máximo de los controles */
  background-color: white; /* Fondo blanco para evitar transparencia */
  z-index: 10; /* Asegurar que esté por encima de los controles */
  padding: 20px; /* Espaciado interno para estética */
}

/* Estilos para los controles (componentes hermanos) debajo de la tabla */
.controls-container {
  margin-top: 450px; /* Espacio suficiente debajo de la tabla fija para que no se solape con los controles */
  width: 100%;
  max-width: 1600px; /* Igualar el ancho máximo de la tabla */
  padding: 20px;
}

/* Estilos para la fila de controles */
.controls-row {
  display: flex;
  justify-content: center; /* Centrar los componentes horizontalmente */
  flex-wrap: wrap; /* Permitir que se ajusten en pantallas pequeñas */
  width: 100%;
}

/* Estilos para cada componente de control */
.controls-row > * {
  flex: 1; /* Cada componente ocupa el mismo espacio proporcional */
  min-width: 200px; /* Mínimo ancho para evitar que se encogan demasiado */
  max-width: 300px; /* Límite máximo para mantener consistencia */
  margin: 0 3px; /* Espaciado lateral mínimo */
}

/* Estilos específicos para la tabla (asumiendo que usa Bootstrap o estilos similares) */
.table {
  width: 100%;
  table-layout: fixed; /* Mantener anchos fijos de columnas */
  border-collapse: collapse; /* Unir bordes para una apariencia limpia */
}

.table th,
.table td {
  padding: 8px;
  box-sizing: border-box;
  white-space: normal;
  word-wrap: break-word;
  text-align: center; /* Centrado por defecto para celdas generales */
  line-height: 1.2;
  border: 1px solid #dee2e6; /* Bordes consistentes con Bootstrap */
}

/* Asegurar que los encabezados de fila se alineen a la izquierda */
.table th[scope="row"] {
  text-align: left !important; /* Forzar alineación a la izquierda para encabezados de fila */
}

/* Estilos responsivos */
@media (max-width: 1200px) {
  .calculator-fixed {
    position: relative; /* Desactivar posición fija en pantallas pequeñas */
    top: 0;
    transform: none; /* Quitar centrado horizontal */
    width: 100%;
    margin-bottom: 20px; /* Espaciado inferior en móvil */
  }

  .controls-container {
    margin-top: 0; /* Eliminar margen superior en móvil */
  }

  .controls-row {
    flex-direction: column; /* Apilar los componentes en pantallas pequeñas */
    align-items: center; /* Centrar verticalmente */
  }

  .controls-row > * {
    flex: 100%; /* Cada componente ocupa el 100% del ancho en móvil */
    margin: 10px 0; /* Espaciado vertical en móvil */
    max-width: 100%; /* Quitar límite máximo en móvil */
  }
}