.container-ventas-region-reciente {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;

  .table {
    width: 100% !important; // Ensure the table takes full width of the container
    table-layout: fixed; // Use fixed table layout to respect column widths

    th,
    td {
      white-space: nowrap; // Prevent text wrapping in cells (if needed)
      overflow: hidden; // Handle overflow if text is too long
      text-overflow: ellipsis; // Add ellipsis for overflow text (optional)
    }

    // Define specific column widths based on your React component
    th:nth-child(1),
    td:nth-child(1) {
      width: 6%; // PAÍS
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 15%; // CLIENTE
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 6%; // MES
    }

    th:nth-child(4),
    td:nth-child(4) {
      width: 10%; // TICKET
    }

    th:nth-child(5),
    td:nth-child(5) {
      width: 8%; // STATUS
    }

    th:nth-child(6),
    td:nth-child(6) {
      width: 5%; // TIPO
    }

    th:nth-child(7),
    td:nth-child(7) {
      width: 18%; // CATEGORÍA
    }

    th:nth-child(8),
    td:nth-child(8) {
      width: 12%; // INTERMEDIARIO
    }

    th:nth-child(9),
    td:nth-child(9) {
      width: 8%; // EJECUTIVO
    }

    // Ensure the table headers and cells align properly
    th {
      text-align: center;
      vertical-align: middle;
    }

    td {
      text-align: center;
      vertical-align: middle;
    }

    // Handle text alignment for specific columns if needed
    td:nth-child(2), // CLIENTE
    td:nth-child(4) { // TICKET
      text-align: start; // Left-align CLIENTE and TICKET columns as per your component
    }

    // Ensure responsiveness (optional, adjust as needed)
    @media (max-width: 768px) {
      th,
      td {
        font-size: 12px; // Reduce font size on smaller screens
      }
    }
  }
}