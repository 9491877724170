/* Ensure .mis-facturas maintains its max-width with higher specificity */
.mis-facturas {
  max-width: 90% !important;
  margin: 0 auto !important;
  padding: 20px !important;
  font-family: "Trebuchet MS", Trebuchet, sans-serif !important;
}

/* Target the custom table class with higher specificity to override Bootstrap */
.mis-facturas .custom-table {
  table-layout: fixed !important; /* Forces fixed column widths */
  width: 100% !important; /* Ensures the table fills its container */
  min-width: 0 !important; /* Prevents Bootstrap from forcing minimum widths */
  border-collapse: collapse !important; /* Evita márgenes entre celdas que puedan alterar los anchos */

  /* Definir anchos directamente en <col> */
  col.status-col { width: 3% !important }
  col.numero-col { width: 7% !important }
  col.fecha-col { width: 7% !important }
  col.importe-col { width: 9% !important }
  col.cliente-col { width: 13% !important }
  col.agencia-col { width: 13% !important }
  col.porcentaje-inter-col { width: 5% !important }
  col.importe-inter-col { width: 8% !important }
  col.participantes-col { width: 7% !important }
  col.porcentaje-edificios-col { width: 5% !important }
  col.importe-edificios-col { width: 8% !important }
  col.neto-col { width: 9% !important }
  col.comision-comercial-col { width: 9% !important }

  /* Estilos generales para th y td */
  th,
  td {
    white-space: normal !important; /* Allows text to wrap */
    overflow: hidden !important; /* Hides overflow content to respect widths */
    text-overflow: ellipsis !important; /* Adds ellipsis for overflow text */
    padding: 0.5rem !important; /* Maintains padding for taller rows */
    font-family: "Trebuchet MS", Trebuchet, sans-serif !important; /* Keeps Trebuchet font */
    box-sizing: border-box !important; /* Incluye padding y bordes en el cálculo del ancho */
  }
}

/* Handle "Detalle" link to ensure full visibility, but respect column width */
.mis-facturas .custom-table td span {
  max-width: 100% !important;
  display: block !important;
  overflow: hidden !important; /* Hides overflow to respect column width */
  text-overflow: ellipsis !important; /* Adds ellipsis if text overflows */
}

/* Ensure table-responsive works correctly with higher specificity */
.mis-facturas .table-responsive {
  overflow-x: auto !important;
  min-width: 100% !important;
}

/* Adjust row height for the entire table */
.mis-facturas .custom-table tr {
  height: 2rem !important;
}

/* Adjust font size for numbers and text to fit within columns if needed */
.mis-facturas .custom-table td,
.mis-facturas .custom-table th {
  font-size: 0.9rem !important;
}

/* Estilos específicos para la tabla dentro del modal */
.wide-modal .table {
  table-layout: fixed !important;
  width: 100% !important;
  font-family: "Trebuchet MS", Trebuchet, sans-serif !important;

  th,
  td {
    white-space: normal !important;
    word-wrap: break-word !important;
    overflow: visible !important;
    padding: 0.3rem !important;
    line-height: 1.2 !important;
    height: 2.4em !important;
    font-size: 1rem !important;
  }

  th:nth-child(1), td:nth-child(1) { width: 30% !important; min-width: 45% !important; max-width: 45% !important; }
  th:nth-child(2), td:nth-child(2) { width: 20% !important; min-width: 15% !important; max-width: 15% !important; }
  th:nth-child(3), td:nth-child(3) { width: 20% !important; min-width: 15% !important; max-width: 15% !important; }
  th:nth-child(4), td:nth-child(4) { width: 20% !important; min-width: 15% !important; max-width: 15% !important; }
  th:nth-child(5), td:nth-child(5) { width: 20% !important; min-width: 15% !important; max-width: 15% !important; }
}

.wide-modal .modal-body {
  overflow-x: auto !important;
}

.wide-modal {
  max-width: 50% !important;
  width: 50% !important;
  margin: 0 auto !important;
}