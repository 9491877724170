.container-facturas-detalle {
  max-width: 90%; // Limita el ancho total al 90% del contenedor padre
  margin: 0 auto; // Centra horizontalmente el contenedor
  padding: 20px; // Añade padding para mejor apariencia
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center; // Centra horizontalmente los elementos hijos (logo y botón)
  width: 100%; // Asegura que ocupe el mismo ancho que el contenedor padre
  max-width: 100%; // Evita que se desborde del contenedor
}

.logo-centered {
  max-width: 100px; // Tamaño ajustado del logo
  height: auto; // Mantiene la proporción
}

.table {
  table-layout: fixed !important; // Asegura que la tabla respete los anchos definidos
  width: 100% !important; // La tabla ocupará el 100% del ancho del contenedor
  border-collapse: collapse !important; // Asegura que no haya espacios entre celdas
}

.table th,
.table td {
  padding: 12px 8px !important; // Aumenta el padding vertical para filas más altas
  font-family: "Trebuchet MS", "Trebuchet", sans-serif !important; // Establece la tipografía
  overflow: hidden !important; // Oculta el texto que desborde (se ajustará por columna)
  text-overflow: ellipsis !important; // Muestra "..." si el texto desborde (se ajustará por columna)
  white-space: nowrap !important; // Evita saltos de línea (se ajustará por columna)
}

.table th {
  background-color: #343a40 !important; // Mantiene el color oscuro de los encabezados
  color: white !important; // Mantiene el texto blanco de los encabezados
  text-align: center !important; // Centra el texto en los encabezados
}

.table tbody tr:nth-child(odd) {
  background-color: #f8f9fa !important; // Estilo para filas impares (table-striped)
}

.table tbody tr:hover {
  background-color: #e9ecef !important; // Estilo para filas al pasar el ratón (table-hover)
}

// Definición explícita de anchos para cada columna
.table .col-status {
  width: 5% !important;
}
.table .col-numero-factura {
  width: 9% !important;
}
.table .col-fecha-factura {
  width: 9% !important;
}
.table .col-cliente {
  width: 18% !important;
  white-space: normal !important; // Permite saltos de línea para texto largo
  word-wrap: break-word !important; // Rompe palabras largas
  overflow: visible !important; // Permite que el contenido se muestre en varias líneas
}
.table .col-intermediario {
  width: 15% !important;
  white-space: normal !important; // Permite saltos de línea para texto largo
  word-wrap: break-word !important; // Rompe palabras largas
  overflow: visible !important; // Permite que el contenido se muestre en varias líneas
}
.table .col-venta {
  width: 9% !important;
}
.table .col-comision {
  width: 9% !important;
}
.table .col-neto {
  width: 9% !important;
}
.table .col-cobrada {
  width: 8% !important;
}
.table .col-editar {
  width: 5% !important;
}

.table td.text-start {
  text-align: left !important; // Asegura que el texto en CLIENTE esté alineado a la izquierda
}

.bg-verde {
  background-color: green !important;
}

.bg-rojo {
  background-color: red !important;
}

.bg-azul {
  background-color: blue !important;
}

.bg-amarillo {
  background-color: yellow !important;
}

.recharts-legend-wrapper {
  display: none !important;
}

.active-filter {
  background-color: lightblue !important; /* Cambia a azul claro */
  border-color: #007bff !important; /* Borde azul para que combine */
}

.row {
  width: 100%; // Asegura que las filas ocupen el 100% del contenedor
  margin-left: 0; // Elimina el margen izquierdo predeterminado de Bootstrap
  margin-right: 0; // Elimina el margen derecho predeterminado de Bootstrap
}

.d-flex {
  width: 100%; // Asegura que los elementos flex ocupen el 100% del contenedor
}