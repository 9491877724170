// Table styling (updated to emphasize margin-top and width)
.edificios {
    table-layout: fixed !important; // Mantén el diseño fijo para respetar los anchos
    width: 80% !important; // Asegúrate de que la tabla use el 80% del contenedor padre
    margin-top: 50px !important; // Asegúrate de que se aplica el margin-top (aumentada especificidad)
    margin-left: auto !important; // Centra la tabla horizontalmente
    margin-right: auto !important; // Centra la tabla horizontalmente
  }
  
  // Container for the logo
  .logo-container {
    text-align: center; // Centers the logo horizontally
    margin-bottom: 1rem; // Adds spacing below the logo (equivalent to Bootstrap's mb-4)
    display: flex; // Use flexbox to ensure proper centering
    justify-content: center; // Horizontally center the logo
  }
  // Styling for the logo image (smaller as requested)
  .logo {
    max-width: 100px; // Maintains the smaller size as requested
    height: auto; // Maintains aspect ratio
  }