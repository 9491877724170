.Admin {
  text-align: center;
  padding: 20px;
  width: 90%;
  margin: 0 auto;

  .logo-container {
    text-align: center;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }

  .logo {
    max-width: 100px;
    height: auto;
  }

  .welcome-text {
    margin-bottom: 1rem;
  }

  .sections-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
  }

  .section-wrapper {
    flex: 1;
    min-width: 0;
  }

  .section {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 0;

    .section-title {
      color: #333;
      font-size: 1.5rem;
      font-weight: 600;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
  }

  .botones {
    .link {
      $size: 140px;

      align-items: center;
      background-color: whitesmoke;
      border-radius: 8px;
      border: 1px solid #ccc;
      color: darkslategrey;
      display: inline-flex;
      flex-direction: column;
      gap: 6px;
      height: $size;
      justify-content: center;
      min-width: $size;
      max-width: $size;
      padding: 0;
      text-decoration: none;
      transition: 0.3s;
      width: $size;

      .icon {
        transition: 0.3s;
        color: inherit;
        font-size: 55px;
      }

      span {
        font-size: .9rem; // Aumentado de 0.5rem a 0.75rem para mejor legibilidad
        text-align: center;
        padding: 0 4px;
        white-space: normal; // Permitir que el texto se divida en varias líneas
        overflow: visible; // Asegurar que el texto no se recorte
        text-overflow: none; // Eliminar el efecto de puntos suspensivos
        line-height: 1.2; // Ajustar el espaciado entre líneas
        max-height: 3.6rem; // Ajustado a 3 * line-height para acomodar dos líneas con texto más grande
      }

      &:hover {
        background-color: #e3011b;
        color: white;

        svg {
          color: white !important;
        }
      }
    }
  }
}