.table-container-a {
  background: white;
  position: fixed;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%; /* Usa todo el ancho disponible */
  max-width: 1600px; /* Restaura el max-width original */
  z-index: 100;
  text-align: center; /* Añadir centrado horizontal del texto */


.table th,
.table td {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
  box-sizing: border-box;
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
  line-height: 1.2;
}

.table-dark {
  background-color: #343a40 !important; /* Gris oscuro para la mayoría de los encabezados */
  color: white !important;
}

.bg-danger {
  background-color: #dc3545 !important; /* Rojo para DESCUENTO */
  color: white !important;
}

.bg-success {
  background-color: #28a745 !important; /* Verde para INVERSIÓN */
  color: white !important;
}

.bg-primary {
  background-color: #007bff !important; /* Azul para CPM */
  color: white !important;
}

}

/* Ajuste del contenedor para dar más espacio a los componentes debajo, sin afectar la tabla */
.programmatic-container {
  margin-left: -50px;
  margin-top: 300px; /* Mantenemos el margen para evitar solapamiento con la tabla */
  overflow-y: auto;
  max-height: calc(100vh - 130px);
  padding-top: 20px;
  width: 110%; /* Usa todo el ancho disponible */
  max-width: none; /* Elimina restricciones heredadas para permitir que los hijos se expandan */
}

/* Ajustamos .full-width dentro de .programmatic-container */
.programmatic-container .full-width {
  display: flex !important;
  justify-content: flex-start !important; /* Mantenemos los componentes pegados */
  width: 100% !important; /* Usa todo el ancho disponible */
  gap: 0 !important; /* Sin espacio entre los componentes */
  max-width: none !important; /* Elimina restricciones de ancho máximo */
}

/* Ajustamos los componentes hijos para que tengan el mismo ancho */
.programmatic-container .full-width > div {
  flex: 1 !important; /* Cada componente crece proporcionalmente y tiene el mismo ancho */
  min-width: 0 !important; /* Elimina el ancho mínimo para que se ajusten completamente */
  max-width: none !important; /* Elimina restricciones de ancho máximo */
}

.btn-primary {
  position: relative;
  z-index: 101;
  display: block;
  margin: 10px auto;
  width: fit-content;
}

/* Mantener los anchos de las columnas de la tabla */
.table th:nth-child(1) { width: 8%; }   /* CIRCUITO */
.table th:nth-child(2) { width: 7%; }    /* EDIFICIOS */
.table th:nth-child(3) { width: 7%; }    /* PANTALLAS */
.table th:nth-child(4) { width: 7%; }    /* AUDIENCIA */
.table th:nth-child(5) { width: 7%; }    /* HORAS ON */
.table th:nth-child(6) { width: 7%; }    /* PLAZO (días) */
.table th:nth-child(7) { width: 7%; }    /* IMPRESIONES */
.table th:nth-child(8) { width: 7%; }    /* IMPACTOS */
.table th:nth-child(9) { width: 7%; }    /* DURACIÓN (segundos) */
.table th:nth-child(10) { width: 10%; }  /* COTIZACIÓN */
.table th:nth-child(11) { width: 7%; }   /* DESCUENTO (si está presente) */
.table th:nth-child(12) { width: 10%; }  /* INVERSIÓN */
.table th:nth-child(13) { width: 6%; }   /* CPM */