.table-ventas-ejecutivo {
  table-layout: fixed !important;
  width: 95% !important;
  margin-top: 20px !important;
  margin-left: auto !important;
  margin-right: auto !important;

  // Definición de anchos para las columnas
  .col-mes { width: 8% !important; }
  .col-100 { width: 40% !important; }
  .col-acumulado { width: 40% !important; }
  .col-valor { width: 13% !important; }
  
  // Estilos generales para celdas
  th,
  td {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    padding: 0.3rem !important;
    box-sizing: border-box !important; // Ensure padding doesn't affect width
  }
  
  // Añadir regla específica para .col-valor para permitir texto en varias líneas
  .col-valor {
    white-space: normal !important; // Permitir que el texto se divida en varias líneas
    overflow-wrap: break-word !important; // Asegurar que las palabras largas se dividan
  }
  
  // Sobrescribe estilos específicos de Bootstrap para esta tabla
  &.table {
    th,
    td {
      min-width: 0 !important;
      border: 1px solid #dee2e6 !important;
    }

    // Estilo para la columna MES en el thead
    thead th.col-mes {
      background-color: #343a40 !important;
      color: white !important;
    }

    // Estilo para la columna MES en el tbody
    tbody th.col-mes {
      background-color: inherit !important;
      color: black !important;
    }

    tr:hover {
      background-color: #f8f9fa !important;
    }

    tr:nth-child(even) {
      background-color: #f8f9fa !important;
    }

    tr.table-dark th,
    tr.table-dark td {
      background-color: #343a40 !important;
      color: white !important;
    }
  }

  .text-start { text-align: left !important; }
  .text-end { text-align: right !important; }
  .text-center { text-align: center !important; }

  .bg-success { background-color: #28a745 !important; color: white !important; }
  .bg-danger { background-color: #dc3545 !important; color: white !important; }
  .bg-primary { background-color: #007bff !important; color: white !important; }
  .text-danger { color: #dc3545 !important; }
  .text-black { color: #000 !important; }
}