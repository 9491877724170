.usuarios-table {
  table-layout: fixed !important; // Fuerza un diseño de tabla fijo
  width: 80% !important; // Ancho del 80% del contenedor padre
  margin-top: 50px !important; // Margin superior de 50px
  margin-left: auto !important; // Centra horizontalmente
  margin-right: auto !important; // Centra horizontalmente

  table {
    width: 100% !important; // Asegura que la tabla ocupe el 100% del contenedor
    border-collapse: collapse !important; // Evita doble borde

    th,
    td {
      padding: 8px !important; // Ajusta el padding
      overflow: hidden !important; // Oculta contenido que exceda (se ajustará por columna)
      border: 1px solid #dee2e6 !important; // Restaura los bordes
    }

    th {
      font-weight: bold !important;
      background-color: #343a40 !important; // Estilo oscuro del encabezado
      color: white !important;
      text-align: center !important;
    }

    // Definición explícita de anchos para cada columna
    .col-nombre {
      width: 10% !important;
    }
    .col-apellido {
      width: 10% !important;
    }
    .col-correo {
      width: 25% !important;
    }
    .col-agencia {
      width: 15% !important;
    }
    .col-rol {
      width: 10% !important;
    }
    .col-edificios {
      width: 30% !important;
      white-space: normal !important; // Permite saltos de línea
      word-wrap: break-word !important; // Rompe palabras largas
      overflow: visible !important; // Permite que el contenido se muestre en varias líneas
      text-align: center !important; // Centra el texto horizontalmente
      vertical-align: middle !important; // Centra el texto verticalmente
    }
    .col-edit {
      width: 5% !important;
      text-align: center !important;
    }
  }
}

// Contenedor para alinear logo y formulario
.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

// Ajuste para el logo
.logo-container {
  margin-bottom: 20px;
}

// Ajuste para el formulario
.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
}